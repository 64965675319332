@import url("https://fonts.googleapis.com/css2?family=Neue+Haas+Grotesk+Display:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aeonik:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Söhne+Mono:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");

:root {
  --primary: #111111;
  --secondary: #666666;
  --background: rgba(255, 255, 255, 0);
  --surface: #ffffff;
  --accent: #0b5cd5;
  --border: rgba(0, 0, 0, 0.08);
  --nav-height: 60px;
  --max-width: 1280px;
  --padding-x: 48px;
  --gradient-1: linear-gradient(135deg, #0b5cd5 0%, #1e73f3 100%);
  --gradient-2: linear-gradient(135deg, #0b5cd5 0%, #0b5cd5 100%);
  --accent-gradient: linear-gradient(135deg, var(--accent) 0%, #ff8a00 100%);
  --text-stroke: -1px -1px 0 var(--accent), 1px -1px 0 var(--accent),
    -1px 1px 0 var(--accent), 1px 1px 0 var(--accent);
  --spacing-mobile: 16px;
  --max-width-mobile: 100%;
  --hero-title-mobile: clamp(36px, 8vw, 48px);
  --section-title-mobile: 24px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Aeonik", sans-serif;
  background-color: var(--background);
  color: var(--primary);
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  background-color: #fbf7f0;
  background-repeat: repeat;
  width: 100vw;
}

.navbar {
  height: var(--nav-height);
  position: fixed;
  width: 100%;
  top: 0;
  backdrop-filter: blur(12px);
  border-bottom: 1px solid var(--border);
  z-index: 100;
}

.nav-content {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 var(--padding-x);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-family: "Söhne Mono", monospace;
  font-weight: 500;
  font-size: 15px;
  text-decoration: none;
  color: var(--primary);
  letter-spacing: -0.02em;
}

.logo-separator {
  color: var(--accent);
  margin: 0 2px;
}

.logo-cursor {
  display: inline-block;
  animation: blink 1s step-end infinite;
  color: var(--accent);
  font-weight: 400;
}

.logo-image {
  width: 25px;
  vertical-align: middle;
  translate: 0 -1px;
  margin-right: 1vw;
  border-radius: 50%;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.nav-links {
  display: flex;
  gap: 40px;
}

.nav-link {
  font-family: "Söhne Mono", monospace;
  color: var(--primary);
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.01em;
  transition: color 0.3s ease;
  position: relative;
  padding: 4px 0;
}

.nav-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(--accent);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.nav-link:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.hero {
  height: 100vh;
  display: flex;
  align-items: center;
  padding: calc(var(--nav-height) + 40px) var(--padding-x) 80px;
}

.hero-content {
  max-width: var(--max-width);
  margin: 0 auto;
  width: 100%;
}

.hero-title {
  font-family: "Neue Haas Grotesk Display", sans-serif;
  font-size: clamp(48px, 8vw, 120px);
  line-height: 0.95;
  font-weight: 500;
  margin: 0;
  letter-spacing: -0.03em;
  position: relative;
  mix-blend-mode: multiply;
}

.hero-line {
  margin-bottom: 0.1em;
  position: relative;
  display: inline-block;

  &:hover {
    color: transparent;
    -webkit-text-stroke: 2px var(--accent);
    transform: skew(-5deg);
    transition: all 0.3s ease;
  }
}

.trademark {
  font-family: "Söhne Mono", monospace;
  font-size: 0.25em;
  vertical-align: super;
  font-weight: 400;
  color: var(--accent);
}

.hero-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  margin-top: 120px;
}

.hero-description {
  font-size: 20px;
  line-height: 1.6;
  color: var(--secondary);
  margin: 0;
  max-width: 520px;
}

.hero-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

.stat {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.stat-number {
  font-family: "Söhne Mono", monospace;
  font-size: 36px;
  font-weight: 500;
  color: var(--accent);
  letter-spacing: -0.02em;
  background: var(--accent-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;

  &::before {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    top: 2px;
    z-index: -1;
    -webkit-text-stroke: 4px var(--background);
  }
}

.stat-label {
  font-size: 14px;
  color: var(--secondary);
  letter-spacing: 0.02em;
}

.work-section {
  padding: 120px var(--padding-x);
  max-width: var(--max-width);
  margin: 0 auto;
  position: relative;
}

.work-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 80px;
}

.section-title {
  font-family: "Neue Haas Grotesk Display", sans-serif;
  font-size: 32px;
  font-weight: 500;
  margin: 0;
  letter-spacing: -0.02em;
}

.work-year {
  font-family: "Söhne Mono", monospace;
  font-size: 15px;
  color: var(--secondary);
}

.work-table {
  background: var(--surface);
  border-radius: 2px;
  overflow: hidden;
}

.table-header {
  display: grid;
  grid-template-columns: 100px 1fr 1fr 120px;
  gap: 40px;
  padding: 24px 32px;
  background: var(--background);
  border-bottom: 1px solid var(--border);
}

.table-header>div {
  font-family: "Söhne Mono", monospace;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: var(--secondary);
}

.table-row {
  display: grid;
  grid-template-columns: 100px 1fr 1fr 120px;
  gap: 40px;
  padding: 32px;
  border-bottom: 1px solid var(--border);
  transition: all 0.3s ease;
  position: relative;
  cursor: pointer;
  background: var(--surface);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border-left: 4px solid transparent;
}

.table-row::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background: var(--accent);
  transform: scaleY(0);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.table-row:hover::before {
  transform: scaleY(1);
}

.table-row.highlighted {
  background: linear-gradient(90deg,
      rgba(255, 77, 0, 0.05) 0%,
      rgba(255, 77, 0, 0.02) 100%);
  transform: translateX(8px);
}

.table-row.highlighted .col-no {
  color: var(--accent);
}

.col-no {
  font-family: "Söhne Mono", monospace;
  font-size: 14px;
}

.col-client {
  font-weight: 500;
}

.col-type {
  color: var(--secondary);
}

.col-year {
  font-family: "Söhne Mono", monospace;
  text-align: right;
}

.table-row::after {
  content: "→";
  position: absolute;
  right: 32px;
  opacity: 0;
  transform: translateX(-8px);
  transition: all 0.3s ease;
  color: var(--accent);
}

.table-row:hover::after {
  opacity: 1;
  transform: translateX(0);
}

.work-section::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  height: 400px;
  background-image: radial-gradient(circle at center,
      rgba(255, 77, 0, 0.02) 0%,
      transparent 70%);
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 1024px) {
  :root {
    --padding-x: 32px;
  }

  .hero-footer {
    grid-template-columns: 1fr;
    gap: 60px;
    margin-top: 80px;
  }

  .hero-stats {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  :root {
    --padding-x: var(--spacing-mobile);
  }

  .hero {
    padding: calc(var(--nav-height) + 20px) var(--padding-x) 40px;
    min-height: 70vh;
  }

  .hero-title {
    font-size: var(--hero-title-mobile);
  }

  .hero-footer {
    grid-template-columns: 1fr;
    gap: 40px;
    margin-top: 60px;
  }

  .hero-description {
    font-size: 18px;
    max-width: 100%;
  }

  .lives-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
  }

  .life-content {
    padding: 24px;

    h3 {
      font-size: 24px;
    }
  }

  .work-table {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    .table-header,
    .table-row {
      min-width: 600px;
    }
  }

  .process-steps {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .background-effects {
    opacity: 0.3;

    .floating-shape,
    .floating-line,
    .gradient-orb {
      transform: scale(0.6);
    }
  }

  .table-header,
  .table-row {
    grid-template-columns: 80px 1fr 100px;
    gap: 24px;
    padding: 24px;
  }

  .col-type {
    display: none;
  }

  .hero-stats {
    grid-template-columns: 1fr;
    gap: 32px;
  }

  .stat {
    flex-direction: row;
    align-items: baseline;
    gap: 16px;
  }

  .table-row.highlighted {
    transform: translateX(4px);
  }

  .work-section::before {
    width: 200px;
    height: 200px;
  }

  .approach-grid,
  .process-steps {
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .hero-badges {
    flex-wrap: wrap;
  }

  .hackathon-badge {
    display: none;
  }
}

/* New section styles */
.approach-section {
  padding: 80px var(--padding-x);
  max-width: var(--max-width);
  margin: 0 auto;
  position: relative;
  background: var(--background);
  overflow: hidden;
}

.artistic-note {
  font-family: "Söhne Mono", monospace;
  font-size: 14px;
  color: var(--accent);
  opacity: 0.6;
  font-weight: normal;
  margin-left: 16px;
}

.lives-container {
  margin-top: 60px;
  position: relative;
  z-index: 1;
}

.life-item {
  gap: 40px;
  margin-bottom: 60px;
  position: relative;
  transform: translateX(0);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.life-item:hover {
  transform: translateX(20px);
}

.life-marker {
  position: relative;
  min-width: 40px;
}

.life-number {
  font-family: "Söhne Mono", monospace;
  font-size: 14px;
  color: var(--accent);
  position: relative;
  z-index: 1;
}

.life-line {
  position: absolute;
  left: 50%;
  top: 24px;
  bottom: -90px;
  width: 1px;
  background: linear-gradient(to bottom, var(--accent) 0%, transparent 100%);
}

.life-item:last-child .life-line {
  display: none;
}

.life-content {
  position: relative;
  padding: 32px;
  background: var(--surface);
  border-radius: 2px;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.03);
  transition: all 0.3s ease;
  backdrop-filter: blur(20px);
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.2);

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(45deg, transparent 40%, rgba(255, 77, 0, 0.05));
    border-radius: inherit;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::before {
    opacity: 1;
  }
}

.life-content:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 60px rgba(0, 0, 0, 0.06);
}

.life-content h3 {
  font-family: "Neue Haas Grotesk Display", sans-serif;
  font-size: 24px;
  margin: 0 0 12px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.life-emoji {
  font-size: 24px;
}

.life-content p {
  color: var(--secondary);
  font-size: 16px;
  line-height: 1.6;
  margin: 0 0 20px;
}

.life-tags {
  display: flex;
  gap: 8px;
}

.life-tag {
  font-family: "Söhne Mono", monospace;
  font-size: 12px;
  padding: 4px 12px;
  background: var(--background);
  border-radius: 12px;
  color: var(--accent);
}

.artistic-elements {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 0;

  .circle-element {
    animation: float 6s ease-in-out infinite;
  }

  .line-element {
    animation: rotate 20s linear infinite;
  }

  .dot-pattern {
    animation: pulse 4s ease-in-out infinite;
  }
}

.circle-element {
  position: absolute;
  top: 10%;
  right: 10%;
  width: 300px;
  height: 300px;
  border: 1px solid var(--border);
  border-radius: 50%;
  opacity: 0.3;
}

.line-element {
  position: absolute;
  top: 40%;
  left: 5%;
  width: 200px;
  height: 1px;
  background: var(--border);
  transform: rotate(-45deg);
}

.dot-pattern {
  position: absolute;
  bottom: 15%;
  right: 15%;
  width: 100px;
  height: 100px;
  background-image: radial-gradient(var(--border) 1px, transparent 1px);
  background-size: 10px 10px;
}

@media (max-width: 1024px) {
  .background-effects {

    .floating-shape,
    .gradient-orb {
      transform: scale(0.8);
    }
  }

  .life-item {
    gap: 24px;
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .approach-section {
    padding: 60px var(--padding-x);
  }

  .lives-container {
    margin-top: 40px;
  }

  .life-content {
    padding: 24px;

    h3 {
      font-size: 20px;
    }

    p {
      font-size: 14px;
    }
  }

  .life-marker {
    min-width: 30px;
  }

  .life-line {
    bottom: -60px;
  }

  .background-effects {

    .floating-shape,
    .gradient-orb {
      transform: scale(0.6);
    }
  }
}

.process-section {
  padding: 120px var(--padding-x);
  background: var(--background);
  position: relative;
}

.process-steps {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 80px;
  margin-top: 60px;
}

.process-step {
  position: relative;
}

.step-number {
  font-family: "Söhne Mono", monospace;
  font-size: 48px;
  color: var(--accent);
  opacity: 0.1;
  position: absolute;
  top: -40px;
  left: -20px;
}

.process-step h3 {
  font-family: "Neue Haas Grotesk Display", sans-serif;
  font-size: 28px;
  margin: 0 0 16px;
}

.process-step::before {
  content: "";
  position: absolute;
  top: -20px;
  left: -20px;
  width: 40px;
  height: 40px;
  background: var(--accent);
  opacity: 0.05;
  border-radius: 50%;
}

.footer {
  padding: 120px var(--padding-x) 40px;
  background: var(--surface);
  border-top: 1px solid var(--border);
}

.footer-content {
  max-width: var(--max-width);
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  margin-bottom: 80px;
}

.footer-logo {
  font-family: "Söhne Mono", monospace;
  font-weight: 500;
  font-size: 15px;
  text-decoration: none;
  color: var(--primary);
  letter-spacing: -0.02em;
  margin-bottom: 24px;
  display: inline-block;
}

.footer-tagline {
  color: var(--secondary);
  font-size: 16px;
  max-width: 280px;
  margin: 0;
}

.footer-links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px;
}

.footer-column h4 {
  font-family: "Söhne Mono", monospace;
  font-size: 14px;
  color: var(--accent);
  margin: 0 0 24px;
  font-weight: 500;
}

.footer-column a {
  display: block;
  color: var(--secondary);
  text-decoration: none;
  margin-bottom: 16px;
  transition: color 0.3s ease;
}

.footer-column a:hover {
  color: var(--primary);
}

.footer-bottom {
  max-width: var(--max-width);
  margin: 0 auto;
  padding-top: 40px;
  border-top: 1px solid var(--border);
  display: flex;
  justify-content: space-between;
  color: var(--secondary);
  font-size: 14px;
}

.accent-text {
  color: var(--accent);
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    gap: 60px;
  }

  .footer-bottom {
    flex-direction: column;
    gap: 16px;
    text-align: center;
  }
}

.hackathon-badge {
  font-size: 12px;
  background: var(--mlh-blue);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  margin-left: 12px;
  font-weight: 500;
  letter-spacing: 0.02em;
}

.hero-badges {
  display: flex;
  gap: 16px;
  margin-top: 40px;
}

.badge {
  font-family: "Söhne Mono", monospace;
  font-size: 14px;
  background: var(--surface);
  border: 1px solid var(--border);
  padding: 8px 16px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.badge:hover {
  border-color: var(--accent);
  transform: translateY(-2px);
}

@keyframes float {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {

  0%,
  100% {
    opacity: 0.3;
  }

  50% {
    opacity: 0.1;
  }
}

.background-effects {
  position: fixed;
  inset: 0;
  z-index: -1;
  overflow: hidden;
  pointer-events: none;
  max-width: var(--max-width);
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);

  .floating-shape,
  .gradient-orb,
  .floating-dots {
    max-width: min(100%, var(--max-width));
    transform-origin: center;
  }
}

.floating-shape {
  position: absolute;
  border: 1px solid rgba(255, 77, 0, 0.1);

  &.shape-1 {
    top: 15%;
    right: 10%;
    width: 400px;
    height: 400px;
    border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
  }

  &.shape-2 {
    bottom: 10%;
    left: 5%;
    width: 300px;
    height: 300px;
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    border-color: rgba(38, 95, 140, 0.1);
  }
}

.floating-line {
  position: absolute;
  top: 40%;
  left: 15%;
  width: 200px;
  height: 1px;
  background: linear-gradient(90deg, transparent, var(--accent), transparent);
  opacity: 0.2;
}

.gradient-orb {
  position: absolute;
  top: 60%;
  right: 15%;
  width: 600px;
  height: 600px;
  background: radial-gradient(circle at center,
      rgba(255, 77, 0, 0.03) 0%,
      rgba(255, 77, 0, 0) 70%);
  animation: pulse 8s ease-in-out infinite;
}

@keyframes morphShape {
  0% {
    border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
  }

  50% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }

  100% {
    border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
  }
}

@keyframes pulse {

  0%,
  100% {
    transform: scale(1);
    opacity: 0.3;
  }

  50% {
    transform: scale(1.1);
    opacity: 0.1;
  }
}

@media (hover: none) {

  .nav-link::after,
  .table-row::after,
  .life-content::before {
    display: none;
  }

  .table-row:active,
  .life-content:active {
    transform: scale(0.98);
  }
}

/* Add new animated elements */
.geometric-maze {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(var(--border) 1px, transparent 1px),
    linear-gradient(90deg, var(--border) 1px, transparent 1px);
  background-size: 50px 50px;
  opacity: 0.1;
  pointer-events: none;
}

.floating-grid {
  position: absolute;
  inset: 0;
  background: radial-gradient(circle at 50% 50%,
      var(--border) 1px,
      transparent 1px),
    radial-gradient(circle at 100% 100%, var(--border) 1px, transparent 1px);
  background-size: 40px 40px;
  opacity: 0.1;
  animation: gridFloat 20s linear infinite;
}

.particle-system {
  position: fixed;
  inset: 0;
  pointer-events: none;
}

.particle {
  position: absolute;
  width: 4px;
  height: 4px;
  background: var(--accent);
  border-radius: 50%;
  opacity: 0;
}

/* Easter egg animations */
@keyframes partyMode {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

.background-effects.party-mode {
  animation: partyMode 2s linear infinite;

  .floating-shape {
    animation: bounce 0.5s ease infinite;
  }

  .particle {
    background: var(--gradient-1);
  }
}

@keyframes gridFloat {
  0% {
    transform: perspective(500px) rotateX(0deg);
  }

  100% {
    transform: perspective(500px) rotateX(360deg);
  }
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px) scale(1.1);
  }
}

/* Update existing animations for better performance */
.floating-shape {
  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;

  &.shape-1 {
    filter: blur(1px);
    mix-blend-mode: multiply;
  }
}

/* Add responsive adjustments */
@media (max-width: 768px) {

  .geometric-maze,
  .floating-grid {
    opacity: 0.05;
  }

  .particle-system {
    display: none;
  }
}

/* Add hover state animations */
.life-content:hover~.background-effects .floating-shape {
  animation-duration: 10s;
  filter: hue-rotate(90deg);
}

.hero-title:hover~.background-effects {
  .geometric-maze {
    animation: gridFloat 10s linear infinite;
  }
}